import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";

import ModalStore, {
  NAMESPACE as MODAL_NAMESPACE,
} from "canary-ui/src/modals/ModalStore";
import RouterStore, {
  NAMESPACE as ROUTER_NAMESPACE,
} from "canary-ui/src/router/RouterStore";
import ToastStore, {
  NAMESPACE as TOAST_NAMESPACE,
} from "canary-ui/src/toasts/ToastStore";

Vue.use(Vuex);
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// TODO: Migrate stores used by canary-ui components to pinia, Once it is done, we can remove this file
// https://linear.app/canary-technologies/issue/GJ-3578/migrate-stores-used-by-canary-ui-components-to-pinia

const store = new Vuex.Store({
  modules: {
    [ROUTER_NAMESPACE]: RouterStore,
    [MODAL_NAMESPACE]: ModalStore,
    [TOAST_NAMESPACE]: ToastStore,
  },
});

const routerStore = getModule(RouterStore, store);
const modalStore = getModule(ModalStore, store);
const toastStore = getModule(ToastStore, store);

export default store;
export { modalStore, routerStore, toastStore };
