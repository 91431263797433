import Vue from "vue";
import Router from "vue-router";

import { RouteName } from "./RouteName";
import { routerStore } from "./store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: RouteName.COMPENDIUM_QR_POSTER,
      path: "/stay/:hotelSlug/qr-poster",
      component: () => import("./views/CompendiumQrPoster.vue"),
    },
    {
      name: RouteName.HOTEL_ROOT,
      path: "/stay/:hotelSlug",
      component: () => import("./views/HotelRoot.vue"),
      children: [
        {
          name: RouteName.HOTEL_HOME,
          path: "",
          component: () => import("./views/HotelHome.vue"),
        },
        {
          name: RouteName.FIND_RESERVATION,
          path: "find-reservation",
          component: () => import("./views/FindReservation.vue"),
        },
        {
          name: RouteName.MY_RESERVATION,
          path: "my-reservation",
          component: () => import("./views/MyReservation.vue"),
        },
        {
          name: RouteName.FACILITY_DETAILS,
          path: "facilities/:facilityUuid",
          component: () => import("./views/FacilityDetails.vue"),
        },
        {
          name: RouteName.PROPERTY_INFO,
          path: "property-info",
          component: () => import("./views/PropertyInfo/PropertyInfo.vue"),
        },
        {
          name: RouteName.RESTAURANT_DETAILS,
          path: "restaurants/:restaurantUuid",
          component: () =>
            import("./views/RestaurantDetails/RestaurantDetails.vue"),
        },
        {
          name: RouteName.EARLY_CHECK_IN_DETAILS,
          path: "early-check-in/:earlyCheckInUuid",
          component: () => import("./views/UpsellEarlyCheckInDetails.vue"),
        },
        {
          name: RouteName.LATE_CHECK_OUT_DETAILS,
          path: "late-check-out/:lateCheckOutUuid",
          component: () => import("./views/UpsellLateCheckOutDetails.vue"),
        },
        {
          name: RouteName.ADDON_DETAILS,
          path: "addon/:addonUuid",
          component: () => import("./views/UpsellAddonDetails.vue"),
        },
        {
          name: RouteName.ROOM_UPGRADE_DETAILS,
          path: "room-upgrade/:roomUpgradeUuid",
          component: () => import("./views/UpsellRoomUpgradeDetails.vue"),
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    routerStore.onRestoreScrollPosition({
      to,
      from,
      savedPosition: savedPosition || null,
    });
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || "Compendium";

  next();
});

export default router;
