import "shared/styles/browser-reset.scss";

import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import dayjsLocaleData from "dayjs/plugin/localeData";
import dayjsLocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsUtc from "dayjs/plugin/utc";
import Vue from "vue";

import sharedAxios from "shared/api/axios";
import type { LanguageCode } from "shared/constants/languages";
import publicApiKeys from "shared/constants/publicApiKeys";
import i18n from "shared/helpers/i18n";
import CanaryAnalytics from "shared/utilities/analytics/CanaryAnalytics";
import { filterStandardEvents } from "shared/utilities/Sentry";

import App from "./App.vue";
import { useAppStore } from "./AppStore";
import router from "./router";
import store, { pinia } from "./store";

declare global {
  interface Window {
    // We should expect this property to be set in the index.html file
    _canary_app_version: string;
  }
}
new CanaryAnalytics();
Vue.config.productionTip = false;
Vue.prototype.axios = sharedAxios;

// Global error handler to catch any swallowed error
if (!publicApiKeys.SENTRY_DSN) {
  Vue.config.errorHandler = (error: Error) => {
    console.error(error);
  };
}

Sentry.init({
  Vue: Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: publicApiKeys.SENTRY_DSN,
  attachProps: false,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  release: window._canary_app_version,
  // Setting to true tracks all components. Can also be an array of component names.
  trackComponents: true,
  beforeSend(event, hint?) {
    return filterStandardEvents(event, hint);
  },
});

// Preload the locale data for the schemaform.
dayjs.extend(dayjsLocaleData);
dayjs.extend(dayjsLocalizedFormat);

// Install the timezone plugin.
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

const IS_PROD = process.env.VUE_APP_ENV === "production";

(async () => {
  if (!IS_PROD) {
    const { tryLoadAndStartRecorder } = await import(
      "@alwaysmeticulous/recorder-loader"
    );
    await tryLoadAndStartRecorder({
      projectId: publicApiKeys.METICULOUS_RECORDING_TOKEN,
      isProduction: IS_PROD,
    });
  }
  const app = new Vue({
    pinia, // Add pinia to the Vue instance
    i18n: await i18n.initialize({
      localeTypes: ["canary-ui", "shared", "guest"],
      onLanguageUpdated: (lang: LanguageCode) => {
        // In vue 2, the pinia store is available after we create vue instace
        // So we need to use setTimeout to wait for the store to be available
        setTimeout(() => {
          const appStore = useAppStore();
          appStore.setLanguage(lang);
        }, 0);
      },
    }),
    router,
    store,
    render: h => h(App),
  });

  app.$mount("#app");
})();

// eslint-disable-next-line no-console
console.debug(window._canary_app_version);

// Send Canary-Version in all requests
sharedAxios.defaults.headers.common["X-Canary-Version"] =
  window._canary_app_version;

sharedAxios.defaults.headers.common[
  localStorage.getItem("CNRY_API_TUNNEL_NO_BLOCK") as string
] = "true";
sharedAxios.defaults.baseURL =
  localStorage.getItem("CNRY_API_URL") || process.env.VUE_APP_API_URL;
