import dayjs from "dayjs";
import { defineStore } from "pinia";
import { ref } from "vue";

import { LanguageCode, languages } from "shared/constants/languages";

export const useAppStore = defineStore("app", () => {
  const language = ref<LanguageCode>(languages.EN);
  const now = ref(dayjs());
  const isAutoUpdatingNow = ref(true);

  function setLanguage(newLanguage: LanguageCode) {
    language.value = newLanguage;
  }

  function setNow(newNow: dayjs.Dayjs) {
    now.value = newNow;
  }

  function setIsAutoUpdatingNow(newIsAutoUpdatingNow: boolean) {
    isAutoUpdatingNow.value = newIsAutoUpdatingNow;
  }

  return {
    language,
    now,
    isAutoUpdatingNow,
    setLanguage,
    setNow,
    setIsAutoUpdatingNow,
  };
});
