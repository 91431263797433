<script setup lang="ts">
import { routerStore } from "@/guest/store";
import CanaryModalContainer from "canary-ui/src/modals/CanaryModalContainer.vue";
import CanaryRouterView from "canary-ui/src/router/CanaryRouterView.vue";
import { provideRouterStore } from "canary-ui/src/router/RouterStore";
import CanaryToastContainer from "canary-ui/src/toasts/CanaryToastContainer.vue";

provideRouterStore(routerStore);
</script>
<template>
  <div id="app">
    <CanaryRouterView :route-hack="$route" />
    <CanaryToastContainer />
    <CanaryModalContainer />
  </div>
</template>

<style lang="scss">
@import "shared/styles/canary-design-system.scss";

body {
  margin: 0;

  // Hide the horizontal overflow to avoid scrollbars during page transitions.
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  --v-spacing: 12px;
  --h-spacing: 16px;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@include breakpoint(xs) {
  #app {
    --v-spacing: 24px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
